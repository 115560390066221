import React from 'react';
import logo from './logo_day.svg';
import logo_novo from './logo_novo.svg';
import contribua_1 from './contribua_1.svg'
import contribua_2 from './contribua_2.svg'
import picture from './picture.jpg'
import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import { SocialIcon } from 'react-social-icons';

function App() {
  return (
    <div className="App">
      <Navbar expand="lg" className='bg-orange' fixed="top">
        <Container>
          <Navbar.Brand href="#home"><img src={logo} alt='DayBorges' className='logo'></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="color-novo border-novo" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              
              <Nav.Link href="#home" className='link'>DAY BORGES</Nav.Link>
              <Nav.Link href="#bandeiras" className='link'>BANDEIRA</Nav.Link>
              <Nav.Link href="#compromisso" className='link'>COMPROMISSOS</Nav.Link>
              <Nav.Link href="#proposts" className='link'>PROPOSTA</Nav.Link>
              <Nav.Link href="#apoie" className='link'>APOIE</Nav.Link>
              <div className='menu-icon'>
                <SocialIcon  style={{ height: 40, width: 40 }} url="https://www.instagram.com/dayborgesnovo" />
                <SocialIcon  style={{ height: 40, width: 40 }} url="https://www.facebook.com/dayborgesnovo/" />
                <SocialIcon  style={{ height: 40, width: 40 }} url="https://mobile.twitter.com/dayborgesnovo" />
                <SocialIcon  style={{ height: 40, width: 40 }} url="https://www.youtube.com/dayborgesnovo" />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="row justify-content-center">
          <div className="row justify-content-center sobre group">
             <Image src={picture} className='picture'></Image>
             <div className="row justify-content-around">
              <div className="col-6">
                <h2 id='home'>DAY BORGES </h2>
              </div>
              <div className="col-6">
                <img src={logo_novo} alt='Novo30' className='logo-middle'></img>

              </div>

             </div>
              <div className="col-sm-9 description">
                Day Borges Doutora em Neurociências, professora universitária e profissional da área da saúde. Com quase 15
                anos de atuação profissional, vivenciei as consequências da incompetência da política na vida das pessoas.
                Trabalhando em hospitais públicos, vi repetidas vezes cirurgias sendo adiadas por falta de insumos básicos,
                tratamentos serem comprometidos pela falta de medicação, vidas se perdem pela irresponsabilidade pública. Como
                professora universitária, sei as dificuldades enfrentadas pelos jovens que não tiveram uma educação básica de
                qualidade e que por isso, não conseguem se desenvolver e alcançar melhores empregos e qualidade de vida. Como
                mulher, sei das inseguranças em andar nas ruas, mesmo durante o dia, frequentemente abordada por pessoas que
                se acostumaram a pedir e receber sem sacrifícios e que sentem-se a vontade e no direito de apropriar-se de
                espaços públicos. Cansada de tudo isso e acompanhando os frequentes escândalos de corrupção, impunidade e
                muito desperdício dos recursos públicos, decidi sair da indignação para a ação. Fui candidata a vice-prefeita
                em São José, fiz MBA em Políticas e Gestão Pública e o curso de Liderança Política pelo RenovaBr, fui
                assessora parlamentar do Deputado Bruno Souza na Assembleia Legislativa e agora estou candidata a Deputada
                Estadual pelo Partido NOVO.
              </div>
          </div>

          <div className="row justify-content-center group compromissos">
            <h2 id='bandeiras'>BANDEIRAS</h2>
            <div className="col-sm-9 description">
              Fiscalização do Estado com combate aos privilégios e desperdício de recursos públicos -
              Educação básica de qualidade e formação técnica para inserção no mercado de trabalho - Saúde de qualidade, com
              gestão responsável dos recursos - Menos impostos e mais simplificação para os pequenos negócios - Combate à
              impunidade
              <br/>
              <br/>
              <br/>
            </div>

            <h2 id='compromisso'>COMPROMISSOS</h2>
            <div className="col-sm-9 description">
              Fiscalização das contas e serviços públicos, pela redução do desperdício e da falta de
              prioridade, inclusive com combate à compra de itens de luxo, conforme a nova lei de licitações. - Combate aos
              privilégios: vou recusar todos os privilégios e mordomias políticos e cortar gastos desnecessários do mandato.
              Também vou lutar contra essas regalias em todos os serviços públicos. Enquanto formos o país dos privilégios,
              jamais seremos um pais de oportunidade. - Eficiência no uso dos recursos públicos avaliando a efetividade de
              políticas públicas através dos resultados e propondo alterações para aquelas que não estão sendo eficazes.
              Política pública deve ser baseada em evidência. - Prioridade nos serviços essenciais: saúde, educação e
              segurança. A Lei orçamentária deve refletir essas prioridades. - Facilitar a vida de quem gera emprego e
              renda. Para que o empreendedor seja tratado com respeito e não como criminoso. - Transparência e prestação de
              contas de absolutamente todos os gastos e ações durante o mandato.
            </div>
          </div>

          <div className="row justify-content-center group propostas">
          <h2>PROPOSTAS FISCALIZAÇÃO</h2>
            <div id ='propostas' className="col-sm-9 description">
              Atuar fortemente na fiscalização das contas, obras e serviços públicos, pela redução
              do desperdício e da falta de prioridade. - Promover a capacitação cidadã para fiscalização do Estado.
              <br/>              <br/>
              DESBUROCRATIZAÇÃO E SEGURANÇA JURÍDICA - Propor e apoiar ações que visem reduzir, simplificar os processos e
              aumentar a segurança jurídica para quem quer empreender. Mais empreendimentos geram mais empregos e este é o
              melhor programa social de desenvolvimento. - Propor melhorias nas obrigações tributárias, ações de
              fiscalização sanitária e processos administrativos. - Revogar leis inúteis, que atrapalham principalmente
              pequenos empreendedores e criam um ambiente instável para quem gera emprego e renda. [O excesso de leis cria
              um ambiente instável e sujeito a interpretação do agente público, cabendo ao cidadão a necessidade de “obter
              favores” para resolver a sua demanda e facilitando a corrupção.] Revogar leis desnecessárias simplifica a
              burocracia e aumenta a segurança jurídica, atraindo mais investimentos e oportunidades de desenvolvimento.
              <br/>              <br/>
              EDUCAÇÃO - Propor avaliações periódicas de desempenho na educação básica, visando melhorar a qualidade do
              ensino e a eficiência dos investimentos realizados. Um ensino de qualidade na base é fundamental para que as
              pessoas, usuárias dos serviços públicos, tenham oportunidades de desenvolvimento. - Incentivar as escolas
              conveniadas, a partir de parcerias público-privada, a fim de atender as demandas por vagas no sistema
              educacional. - Propor análise de mercado por região do estado, a fim de identificar as demandas regionais e
              incentivar a formação técnica específica que movimentará a economia e promoverá melhores condições de vida.
              <br/>              <br/>
              SAÚDE - Incentivar as parcerias público-privadas para atender as filas de espera para exames e cirurgias
              eletivas - Implantar a avaliação dos serviços de saúde, em todas as suas etapas, permitindo ao cidadão indicar
              as falhas e ao gestor atuar de forma mais eficiente. - Fiscalizar e cobrar qualidade nos serviços de
              saneamento básico, como cuidado à saúde das pessoas e a preservação do meio ambiente. SEGURANÇA - Propor e
              apoiar medidas que garantam a punição dos criminosos, bem como a correta aplicação e distribuição dos recursos
              disponíveis, através de critérios técnicos. A impunidade é o pior incentivo que entregamos para uma sociedade.
              <br/>              <br/>
              QUALIDADE DOS SERVIÇOS PÚBLICOS - Apoiar avaliação regular de desempenho de todos os servidores públicos do
              estado, a fim de valorizar aqueles que se destacarem pela qualidade e profissionalismo e instaurar medidas
              disciplinares a quem não estiver desempenhando adequadamente suas atribuições.
            </div>
          </div>
        </div>
        <div id='apoie' className=''>
        <br/>
        <br/> 
        <div className="row justify-content-center">
          <div className="col-8">
            <img src={contribua_1} alt='DayBorges' className='contribua'></img>
          </div>
        </div>
        <br/>
        <br/> 
        <a href='https://queroapoiar.com.br/dayborges' className='quero-apoiar'>https://queroapoiar.com.br/dayborges</a>
        <br/>         <br/>
        <br/> 
        <div className="row justify-content-center">
        <div className="col-8">
         
          <a href="https://wa.me/5548991843003"> <img src={contribua_2} alt='DayBorges' className='contribua'></img></a>
          </div>
        </div>
          <br/>              <br/>
        </div>

        
        <div className="row footer">
          <div className="col-4 icon-footer">
              <SocialIcon className='icon-footer' style={{ height: 25, width: 25 }} url="https://www.instagram.com/dayborgesnovo" />
              <SocialIcon className='icon-footer' style={{ height: 25, width: 25 }} url="https://www.facebook.com/dayborgesnovo/" />
              <SocialIcon className='icon-footer' style={{ height: 25, width: 25 }} url="https://mobile.twitter.com/dayborgesnovo" />
              <SocialIcon className='icon-footer' style={{ height: 25, width: 25 }} url="https://www.youtube.com/dayborgesnovo" />
              <p style={{ marginLeft: 5}}><a href='https://www.instagram.com/dayborgesnovo' className='ig-link'> @dayborgesnovo</a></p>
          </div>
          <div className="col-4">
            <img src={logo} alt='DayBorges 30303' className='day-footer'></img>
          </div>
          <div className="col-4">
            <img src={logo_novo} alt='Novo30' className='logo-footer'></img>
          </div>
        </div>
    </div>
  );
}

export default App;
